import { dateFormat } from ".";

export const myMenteeColumns = [
  {
    field: "full_name",
    headerName: "Name",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Professional",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Contact",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Location",
    flex: 1,
    id: 2,
  },
  {
    field: "attn_program",
    headerName: "Attended Programs",
    flex: 1,
    id: 2,
  },
  {
    field: "last_attend_program",
    headerName: "Last Attend Program",
    flex: 1,
    id: 1,
  },
];

export const discussionColumns = [
  {
    field: "program_name",
    headerName: "Program Name",
    flex: 1,
    id: 1,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    id: 2,
  },
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    id: 3,
  },
  {
    field: "task",
    headerName: "Tasks",
    flex: 1,
    id: 4,
  },
  {
    field: "users",
    headerName: "Users",
    flex: 1,
    id: 5,
  },
  {
    field: "comments",
    headerName: "Comments",
    flex: 1,
    id: 6,
  },
  {
    field: "last_update_by",
    headerName: "Last update by",
    flex: 1,
    id: 7,
  },
];

export const memberRequestColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    id: 1,
  },
  {
    field: "reason",
    headerName: "Reason",
    flex: 1,
    id: 2,
  },
  {
    field: "location",
    headerName: "Location",
    flex: 1,
    id: 3,
  },
];

export const launchProgramColumns = [
  {
    field: "categories",
    headerName: "Category",
    flex: 1,
    id: 0,
  },
  {
    field: "program_name",
    headerName: "Program Name",
    flex: 1,
    id: 1,
  },
  {
    field: "launch_date",
    headerName: "Launch Date",
    flex: 1,
    id: 2,
  },
  {
    field: "launch_by",
    headerName: "Launch by",
    flex: 1,
    id: 1,
  },
];


export const certificateColumns = [{
        field: 'category_name',
        headerName: 'Category',
        flex: 1,
        id: 0,
        for: ['mentor', 'mentee','admin']
    },
    {
        field: 'program_name',
        headerName: 'Program Name',
        flex: 1,
        id: 1,
        for: ['mentor', 'mentee','admin']
    },
    {
        field: 'location',
        headerName: 'Location',
        flex: 1,
        id: 1,
        for: ['mentee']
    },
    {
        field: 'start_date',
        headerName: 'Start Date',
        flex: 1,
        id: 1,
        for: ['mentee'],
        renderCell: (params) => {
            return <div>{dateFormat(params.row.start_date)}</div>
        }
    },
    {
        field: 'end_date',
        headerName: 'End Date',
        flex: 1,
        id: 1,
        for: ['mentee'],
        renderCell: (params) => {
            return <div>{dateFormat(params.row.end_date)}</div>
        }
    },
    {
        field: 'approved_date',
        headerName: 'Approved Date',
        flex: 1,
        id: 1,
        for: ['mentee'],
        renderCell: (params) => {
            return <div>{dateFormat(params.row.end_date)}</div>
        }
    },
    {
        field: 'approved_by',
        headerName: 'Approved by',
        flex: 1,
        id: 1,
        for: ['mentee']
    },

    {
        field: 'reason',
        headerName: 'Reason',
        flex: 1,
        id: 1,
        for: ['mentee'],
    },
    {
        field: 'reject_date',
        headerName: 'Rejected Date',
        flex: 1,
        id: 1,
        for: ['mentee'],
        renderCell: (params) => {
            return <div>{dateFormat(params.row.reject_date)}</div>
        }
    },
    {
        field: 'rejected_by',
        headerName: 'Rejected by',
        flex: 1,
        id: 1,
        for: ['mentee']
    },

    {
        field: 'Mentee_count',
        headerName: 'Mentees',
        flex: 1,
        id: 2,
        for: ['mentor','admin']
    }, {
        field: 'pass_mentee_count',
        headerName: 'Pass',
        flex: 1,
        id: 1,
        for: ['mentor']
    },
    {
        field: 'fail_mentee_count',
        headerName: 'Fail',
        flex: 1,
        id: 1,
        for: ['mentor']
    },
    {
        field: 'requested_date',
        headerName: 'Request Date',
        flex: 1,
        id: 1,
        for: ['mentor','admin'],
        renderCell: (params) => {
            return <div>{dateFormat(params.row.requested_date)}</div>
        }
    },
    {
        field: 'request_by',
        headerName: 'Requested by',
        flex: 1,
        id: 1,
        for: ['admin']
    },
    {
        field: 'updated_at',
        headerName: 'Last updated Date',
        flex: 1,
        id: 1,
        for: ['mentor','admin'],
        renderCell: (params) => {
            return <div>{dateFormat(params.row.updated_at)}</div>
        }
    },
    {
        field: 'last_updated_by',
        headerName: 'Last updated by',
        flex: 1,
        id: 1,
        for: ['mentor', 'admin']
    }
];
export const certificateMenberColumns = [
  {
    field: "mentee_name",
    headerName: "Mentee",
    flex: 1,
    id: 1,
  },
  {
    field: "program_name",
    headerName: "Program Name",
    flex: 1,
    id: 1,
  },

];
